import { convertDateStringToDate, getLocale } from '@naturehouse/nh-essentials/lib/dates/date';

export function isBefore(dateOne: Date, dateTwo: Date): boolean {
    return new Date(formatDate(dateOne)) < new Date(formatDate(dateTwo));
}

export function isSame(dateOne: Date, dateTwo: Date): boolean {
    return (
        dateOne.getFullYear() === dateTwo.getFullYear() &&
        dateOne.getMonth() === dateTwo.getMonth() &&
        dateOne.getDate() === dateTwo.getDate()
    );
}

export function isBetweenDates(date: Date, dateStart: Date, dateEnd: Date): boolean {
    return date >= dateStart && date <= dateEnd;
}

export function formatDateForUrl(date: Date): string {
    const month = formatNumberWithLeadingZero(date.getMonth() + 1);
    const day = formatNumberWithLeadingZero(date.getDate());
    return `${date.getFullYear()}-${month}-${day}`;
}

export function formatToLocalizedDate(date: Date): string {
    const language = getLocale();
    const options: StandardObjectInterface = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    };

    return date.toLocaleString(language, options);
}

export function formatDate(date: Date): string {
    const day: string = formatNumberWithLeadingZero(date.getDate());
    const month: string = formatNumberWithLeadingZero(date.getMonth() + 1);
    return `${date.getFullYear()}-${month}-${day}`;
}

export function formatNumberWithLeadingZero(number: number): string {
    return String(number).padStart(2, '0');
}

export function convertViewDateToApplicationDate(dateString: string): string {
    if (dateString.length === 0) {
        return '';
    }

    const date = convertDateStringToDate(dateString);
    return formatDateForUrl(date);
}

export function getWeekDays(locale = 'default'): string[] {
    const baseDate: Date = new Date();
    const weekDays: string[] = [];

    // Set date to monday
    baseDate.setDate(baseDate.getDate() - baseDate.getDay() + 1);

    for (let i = 0; i < 7; i++) {
        weekDays.push(
            new Date(baseDate).toLocaleString(locale, {
                weekday: 'short'
            })
        );

        // Proceed to next day
        baseDate.setDate(baseDate.getDate() + 1);
    }

    return weekDays;
}

export function getFullMonthName(month: Date, locale = 'default'): string {
    return month.toLocaleString(locale, { month: 'long', year: 'numeric' });
}

export function getCurrentMonthNumber(date: Date = new Date()): number {
    return date.getMonth() + 1;
}

export function getCurrentYear(date: Date = new Date()): number {
    return date.getFullYear();
}

export function getYears(startYear: number, endYear: number): number[] {
    const years: number[] = [];

    for (let i = startYear; i <= endYear; i++) {
        years.push(i);
    }

    return years;
}

export function getMonths(year: number, startDate: Date, endDate: Date): number[] {
    const months: number[] = [];
    const startYear: number = startDate.getFullYear();
    const endYear: number = endDate.getFullYear();

    let startMonth = 1;
    let endMonth = 12;

    if (year === startYear) {
        startMonth = startDate.getMonth() + 1;
    }

    if (year === endYear) {
        endMonth = endDate.getMonth() + 1;
    }

    for (let i = startMonth; i <= endMonth; i++) {
        months.push(i);
    }

    return months;
}

export function getMonthsDiff(fromDate: Date, toDate: Date): number {
    const yearDiff = toDate.getFullYear() - fromDate.getFullYear();
    const monthDiff = toDate.getMonth() - fromDate.getMonth();
    return yearDiff * 12 + monthDiff;
}
